/*****************************************************************/
/***************************** FONTS *****************************/
/*****************************************************************/
@font-face {
  font-family: "CairoRegular";
  src: local("CairoRegular"), url("./fonts/Cairo-Regular.ttf") format("truetype");
  font-weight: 400;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "CairoRegular", sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App {
  width: 100vw
}

.obligation-aproval-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow-x: hidden;
  background-color: #fafafa;
  padding: 80px 10%;
}

.obligation-aproval {
  width: 100%;
  padding: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #FFF;
}
.obligation-aproval  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #6A2A53;
    font-size: 1.5rem;
}

.obligation-aproval  h3 {
  text-align: right;
  direction: rtl;
  margin-bottom: 15px;
  color: #6A2A53;
  font-size: 1.2rem;
}

.obligation-aproval  p {
  text-align: right;
  direction: rtl;
  margin-bottom: 20px;
}

.obligation-aproval img {
      display: block;
      width: 100%;
      margin-bottom: 30px;
      object-fit: cover;
}

.labtest {
	padding: 5px 15px;
	border-radius: 10px;
	position: relative;
	border: 1px solid #CCC;
	margin: 15px 0;
}

.labtest h4 {
  direction: rtl;
  text-align: right;
	font-weight: bold;
	font-size: 16px;
	margin: 0 0 15px;
}

.labtest p {
	color: #444;
	font-size: 14px;
}

.labtest .stage {
	background-color: #eee;
	color: #24d7d3;
	font-size: 12px;
	padding: 1px 5px;
	border-radius: 20px;
	margin: 0 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.details_btn {
	background-color: transparent;
	font-size: 1rem;
  font-weight: bold;
	color: #6a2a53;
	position: absolute;
	bottom: 10px;
	left: 10px;
	cursor: pointer;
  border: none;
  outline: none;


}

.home-page {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fafafa;
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page img {
  display: block;
  width: 280px;
  height: auto;
}

.action {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.action button {
  width: 180px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #6a2a53;
  background-color: transparent;
  color: #6a2a53;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 20px 15px 0;
}

.action button:hover {
  background-color: #EEE;
}

.action button:last-of-type {
  background-color: #6a2a53;
  color: #FFF;
}

.action button:last-of-type:hover {
  background-color: #5f254a;
  color: #FFF;
}

.action button:disabled {
  background-color: #4d3d47;
  color: #FFF;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.modal-container.show {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 0 1.2rem 25px;
  width: 40%;
  min-height: 350px;
  max-height: 600px;
  max-width: 1900px;
  min-width: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 999;
}

.modal-content img {
  position: absolute;
  left: 23px;
  top: 19px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.5s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 50%;
}
.modal-content>h3 {
  text-align: center;
  margin: 40px auto;
  color: #6A2A53;
  font-size: 1.5rem;
}
.details {
  width: 100%;
  padding: 20px;
}
.details label {
  direction: rtl;
  text-align: right;
  display: block;
  margin-bottom: 10px;
  color: #34065fc9;
  font-size: 14px;
  font-weight: bold;
}
.details input, .details textarea {
  direction: rtl;
  text-align: right;
  width: 100%;
  padding: 10px;
  border-radius: 0;
  border: 1px solid #eee;
  margin-bottom: 10px;
  resize: none;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.actions button {
  width: 100px;
  padding: 4px 0;
  border-radius: 10px;
  border: 1px solid #6a2a53;
  background-color: transparent;
  color: #6a2a53;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin: 20px 10px 0;
}

.actions button:hover {
  background-color: #EEE;
}

.actions button:last-of-type {
  background-color: #6a2a53;
  color: #FFF;
}

.actions button:last-of-type:hover {
  background-color: #5f254a;
  color: #FFF;
}

.actions button:disabled {
  background-color: #4d3d47;
  color: #FFF;
}

.no_obligation {
  width: 60%;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 20px;
  padding: 20px;
  border: 1px solid #6a2a53;
  border-radius: 10px;
}

.error-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  font-size: 20px;
  padding: 20px;
  border: 1px solid #6a2a53;
  border-radius: 10px;
}

.resetPassword_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#24d7d3 0%, #6a2a53 100%);
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.resetPassword {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
}

.resetPassword_form {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px
}

.resetPassword_form h2 {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;

}

.resetPassword_image {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px
}

.resetPassword_image img {
  width: 80%;
  object-fit: contain;
}

.input_container {
  width: 100%;
  margin-bottom: 20px;
}

.input_container label {
  display: block;
  margin-bottom: 5px;
  color: #2d2d2d;
  font-size: 15px;
  font-weight: 700;
  direction: rtl;
  text-align: right;
}

.input_container label span {
  color: #C00;
}

.input_container input {
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #eee;
  margin-bottom: 10px;
  resize: none;
  direction: rtl;
  text-align: right;
  font-size: 17px;
  background: #e7e7e7;

}

.input_container input:focus {
  outline: none;
  border: 1px solid #6a2a53;
}

.input_container input::placeholder {
  color: #888;
  font-family: 'CairoRegular', sans-serif;
}

.input_container svg {
  color: #888;
}

.resetPassword_form .btn {
  width: 100%;
  padding: 10px 0;
  border-radius: 25px;
  border: 1px solid #6a2a53;
  background-color: #6a2a53;
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'CairoRegular', sans-serif;
}

.apps_icons_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.apps_icons_container img {
  height: 60px;
  margin: 0 10px;
  cursor: pointer;
}

.error_msg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  font-size: 14px;
  color: #7c2e2e;
  text-align: center;
}

.input_form {
  position: relative;

}

.input_form svg {
  position: absolute;
  top: 12px;
  left: 18px;
  font-size: 16px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.obligation-aproval .apps_icons_container {
  justify-content: flex-end;
  margin-top: 40px
}

.obligation-aproval .apps_icons_container a {
  margin: 0 0 0 20px;
}

.obligation-aproval .apps_icons_container img {
  margin: 0;
}

.reset-logo {
  display: block;
  width: 55%;
  margin-bottom: 35px;
}
.reset-img {
  display: block;
  width: 45px;
  margin: 0 auto;
}
@media(max-width: 768px) {
  .resetPassword_container {
    height: auto;
    background-image: none;
    align-items: flex-start;
  }
  .resetPassword {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    box-shadow: none;
  }
  .resetPassword_form {
    width: 100%;
    padding: 20px 0;
    justify-content: flex-start;
  }
  .resetPassword_form h2 {
    font-size: 18px;
    margin-bottom: 40px;
    margin-top: 0;
    color: #6a2a53;
    font-weight: bold;
    text-align: center;
  }
  .resetPassword_form p {
    font-size: 18px;
    margin-bottom: 0;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
  .reset-logo {
    margin-top: 20px;
    margin-bottom: 50px;
    height: 85px !important;
    width: auto !important;
  }
  .reset-img {
    width: 60px;
    margin-bottom: 5px !important;
  }
  .resetPassword_image {
    width: 100%;
    display: none;
  }
  .apps_icons_container {
    margin-top: 30px;
  }
  .apps_icons_container a {
    margin-bottom: 20px;
  }
  .apps_icons_container img {
   height: 50px;
  }
  .resetPassword_form .btn {
    border-radius: 15px;
    padding: 15px 0;
    margin-top: 15px;
    font-size: 16px;
    font-weight: bold;
  }
}